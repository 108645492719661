






















import { defineComponent } from '@vue/composition-api';
import Keycloak from '@/modules/auth/api/keycloak';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';

export default defineComponent({
    name: 'UnverifiedOrganization',
    setup(props, { root }) {
        const logout = async () => {
            (root as any).$toastr.removeByType('info');
            store.commit.auth.CLEAR_USER();
            store.commit.notificationEngine.CLEAR_NOTIFICATIONS();
            // await Keycloak.logout();
            await Auth.logout();
            root.$router.push({ name: 'home' });
        };

        return { logout };
    },
    async beforeRouteEnter(to: any, from: any, next: any) {
        if (store.getters.auth.isAuthenticated) {
            return next();
        }
        try {
            const { data: user } = await Auth.user();
            store.commit.auth.SET_USER(user);
            if (to.meta?.feature && !store.getters.auth.isEnabled(to.meta.feature)) {
                return next({ name: '404' });
            }
            return next();
        } catch (e) {
            return Keycloak.login(to.name);
        }
    },
});
